import React from "react";
import { handleSubmit } from "../../pages/AppDashboard/home-page-util-functions";

const SubmitGeneration = ({
  ideaInputText,
  dropDownOptions,
  setAlertOptions,
  setIsLoading,
  setIsVideo,
  setVideoOptions,
  videoOptions,
  userId,
  setShowRateLimitToast,
  setRateLimitDuration,
  setIdeaInputText,
  setUserData,
}) => {
  return (
    <button
      type="submit"
      className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-[#6425a3] px-4 py-2.5 text-base font-bold text-white transition-all duration-300 ease-in-out hover:bg-[#4a1a7a] hover:scale-102 hover:shadow-lg hover:shadow-[#6425a3]/30 w-full mt-2.5"
      onClick={() =>
        handleSubmit(
          ideaInputText,
          dropDownOptions,
          setAlertOptions,
          setIsLoading,
          setIsVideo,
          setVideoOptions,
          videoOptions,
          userId,
          setShowRateLimitToast,
          setRateLimitDuration,
          setIdeaInputText,
          setUserData
        )
      }
    >
      <span className="text-base">Generate</span>
      <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
        <div className="relative h-full w-8 bg-white/20" />
      </div>
    </button>
  );
};

export default SubmitGeneration;
