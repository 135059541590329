import React from "react";
import { FiCreditCard, FiArrowUp, FiDollarSign, FiAward } from "react-icons/fi";
import { motion } from "framer-motion";

const SubscriptionManagement = ({ userData, onOpenPricingModal }) => {
  const isSubscribed = userData?.subscription_tier !== "free";

  return (
    <motion.div 
      className="bg-gradient-to-br from-zinc-800 to-zinc-900 p-6 rounded-lg shadow-lg flex flex-col h-full transition-all duration-300 hover:shadow-xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <h2 className="text-2xl font-bold text-zinc-100 mb-4 flex items-center tracking-wide">
        <FiCreditCard className="w-6 h-6 mr-3 text-purple-400" />
        Manage Subscriptions
      </h2>
      <div className="bg-zinc-800 rounded-lg p-4 mb-4">
        <div className="flex items-center justify-between mb-2">
          <span className="text-zinc-300 font-medium">Current Tier:</span>
          <span className="font-semibold text-purple-400 text-lg tracking-wide">
            {userData?.subscription_tier?.toUpperCase()}
          </span>
        </div>
        {/* <div className="relative">
          <div className="flex mb-1 items-center justify-between">
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-600 bg-purple-200 tracking-wider">
              Usage
            </span>
            <span className="text-xs font-medium inline-block text-purple-600">
              {userData?.usage || 0}%
            </span>
          </div>
          <div className="overflow-hidden h-2 mb-1 text-xs flex rounded bg-purple-200">
            <div style={{ width: `${userData?.usage || 0}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
          </div>
        </div> */}
      </div>
      <ActionButton
        icon={FiArrowUp}
        text={isSubscribed ? "Manage Plan" : "Subscribe"}
        primary={true}
        onClick={onOpenPricingModal}
      />
      <ActionButton
        icon={FiDollarSign}
        text="Pricing and Usage Details"
        primary={false}
        onClick={onOpenPricingModal}
      />
    </motion.div>
  );
};

const ActionButton = ({ icon: Icon, text, primary, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02, boxShadow: `0 0 15px ${primary ? 'rgba(167, 139, 250, 0.5)' : 'rgba(82, 82, 91, 0.5)'}` }}
    whileTap={{ scale: 0.98 }}
    className={`${primary ? 'bg-purple-600 hover:bg-purple-700' : 'bg-zinc-700 hover:bg-zinc-600'} text-white px-4 py-2 rounded-lg transition-all duration-300 flex items-center justify-center mb-2 shadow-md font-medium tracking-wide`}
    onClick={onClick}
  >
    <Icon className="w-4 h-4 mr-2" />
    {text}
  </motion.button>
);

export default SubscriptionManagement;
