import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PROJ_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJ_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJ_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
// const analytics = firebase.analytics();

export async function handleSubscribe(userData, priceId) {
  if (!userData || !userData.user_id) {
    console.error("User data or user ID is missing");
    // Handle this error, maybe show a message to the user
    return;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/create-checkout-session`,
      {
        price_id: priceId,
        user_id: userData.user_id,
        base_url: process.env.REACT_APP_BASE_URL,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      window.location.href = response.data.url;
    } else {
      console.error("Failed to create checkout session");
      console.error("Error details:", response.data);
      // Handle this error, maybe show a message to the user
    }
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    // Handle this error, maybe show a message to the user
  }
}
