export function Checkmark({
  height = "27px",
  width = "27px",
  color = "#ffffff",
  fill = "none",
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
    >
      <g id="Layer_2" data-name="Layer 2">
        <circle cx="12" cy="12" fill={fill} r="10.75" />
        <path
          d="m11 15.5a1.00385 1.00385 0 0 1 -.707-.29346l-3-2.99951a.99989.99989 0 1 1 1.414-1.41403l2.25879 2.2583 4.29492-4.72363a.99945.99945 0 1 1 1.47852 1.34521l-5 5.49951a1.00124 1.00124 0 0 1 -.71582.32764h-.02341z"
          fill={color}
        />
      </g>
    </svg>
  );
}
