import React from "react";

interface DotPatternBackgroundProps {
  dotSize?: number;
  dotColor?: string;
  backgroundColor?: string;
  gap?: number;
  className?: string;
  style?: React.CSSProperties;
  fade?: boolean;
  [key: string]: any;
}

export const BackgroundDots: React.FC<DotPatternBackgroundProps> = ({
  dotSize = 0.8, // Reduced from 1
  dotColor = "rgba(216, 191, 216, 0.1)", // Added transparency
  backgroundColor = "transparent",
  gap = 32, // Increased from 20
  className,
  fade = true,
  style,
  ...props
}) => {
  const encodedDotColor = encodeURIComponent(dotColor);

  const maskStyle: React.CSSProperties = fade
    ? {
        maskImage: "radial-gradient(circle, white 5%, transparent 95%)",
        WebkitMaskImage: "radial-gradient(circle, white 5%, transparent 95%)",
      }
    : {};

  const backgroundStyle: React.CSSProperties = {
    backgroundColor,
    zIndex: -1,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='${gap}' height='${gap}' viewBox='0 0 ${gap} ${gap}' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='${encodedDotColor}' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='${dotSize}' cy='${dotSize}' r='${dotSize}'/%3E%3C/g%3E%3C/svg%3E")`,
    ...maskStyle,
    ...style,
  };

  return (
    <div
      className={`fixed inset-0 z-0 h-full w-full ${className}`}
      style={backgroundStyle}
      {...props}
    />
  );
};

export default BackgroundDots;
