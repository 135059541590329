export function CrystalBall({
  height = "27px",
  width = "27px",
  color = "#7b1fa2", // Bright blue/purple color
  style,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
      xml="preserve"
      height={height}
      width={width}
      strokeWidth="10"
      strokeMiterlimit="10"
      style={style}
    >
      <style type="text/css"></style>
      <g id="Line">
        <path
          d="M141.8,357.4c-16.7,1.7-28.9,16.6-27.2,33.3c0.9,8.7,5.5,16.7,12.8,21.9l-41.3,49.9c-3.6,4.3-3,10.7,1.3,14.2   c1.8,1.5,4.1,2.4,6.5,2.4h324.2c5.6,0,10.2-4.6,10.2-10.1c0-2.4-0.9-4.7-2.4-6.5l-41.3-49.8c13.9-9.4,17.5-28.3,8.1-42.3   c-5.1-7.5-13.4-12.4-22.4-13.2C503.5,251.1,427.5,33,256,33S8.5,251,141.8,357.4L141.8,357.4z M115.6,458.6l33.7-40.6h213.4   l33.7,40.6H115.6z M377.6,387.8c0,5.6-4.6,10.2-10.2,10.2H144.6c-5.6,0-10.2-4.6-10.2-10.2s4.6-10.2,10.2-10.2h223   C373,377.6,377.6,382.2,377.6,387.8z M256,53.4c167,1.7,223.7,222.1,78.4,304H177.6C32.3,275.5,89,55,256,53.4z"
          fill={color}
        />
        <path
          d="M43.2,83.8c11.2,0,20.2,9.1,20.2,20.2c0,5.6,4.6,10.2,10.2,10.2s10.2-4.6,10.2-10.2c0-11.2,9.1-20.2,20.2-20.2   c5.6,0,10.2-4.6,10.2-10.2c0-5.6-4.6-10.2-10.2-10.2c-11.2,0-20.2-9.1-20.2-20.2c0-5.6-4.6-10.2-10.2-10.2   c-5.6,0-10.2,4.6-10.2,10.2c0,11.2-9.1,20.2-20.2,20.2C37.6,63.4,33,68,33,73.6S37.6,83.8,43.2,83.8z M73.6,70c3.9,3.3,3.9,4,0,7.3   C69.7,73.6,69.7,73.6,73.6,70z"
          fill={color}
        />
        <path
          d="M468.8,347.2c-11.2,0-20.2-9.1-20.2-20.2c0-5.6-4.6-10.2-10.2-10.2c-5.6,0-10.2,4.6-10.2,10.2   c0,11.2-9.1,20.2-20.2,20.2c-5.6,0-10.2,4.6-10.2,10.2c0,5.6,4.6,10.2,10.2,10.2c11.2,0,20.2,9.1,20.2,20.2   c0,5.6,4.6,10.2,10.2,10.2s10.2-4.6,10.2-10.2c0-11.2,9.1-20.2,20.2-20.2c5.6,0,10.2-4.6,10.2-10.2   C479,351.8,474.4,347.2,468.8,347.2z M438.4,361c-3.9-3.3-3.9-4,0-7.3C442.3,357.4,442.3,357.4,438.4,361z"
          fill={color}
        />
      </g>
    </svg>
  );
}
