import React from 'react';
import style from './terms-of-use.module.css';

export const TermsOfUse = () => {
  return (
    <div className={style['terms-page-wrapper']}>
      <div className={style['terms-of-use']}>
        <h1>Terms of Use</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>
        
        <h2>1. Acceptance of Terms</h2>
        <p>Welcome to Torsera. By accessing or using our video generation service, you agree to be bound by these Terms of Use.</p>
        
        <h2>2. Description of Service</h2>
        <p>Torsera provides a web-based platform for generating videos using AI technology ("the Service").</p>
        
        <h2>3. User Responsibilities</h2>
        <p>You are solely responsible for the content you generate using our Service. You agree not to use the Service for any unlawful or prohibited purpose.</p>
        
        <h2>4. Intellectual Property Rights</h2>
        <p>Users retain the rights to the content they create using the Service. However, you grant Torsera a non-exclusive license to use, reproduce, and display your content for the purpose of providing and improving the Service.</p>
        
        <h2>5. Limitation of Liability</h2>
        <p>Torsera LLC is not responsible for any videos or content generated by users through our Service. We provide the tool, but we do not control or endorse user-generated content.</p>
        
        <h2>6. Indemnification</h2>
        <p>You agree to indemnify and hold harmless Torsera LLC, its officers, directors, employees, and agents from any claims, damages, or expenses arising from your use of the Service or violation of these Terms.</p>
        
        <h2>7. Modifications to Service and Terms</h2>
        <p>Torsera reserves the right to modify or discontinue the Service at any time. We may also update these Terms of Use from time to time.</p>
        
        <h2>8. Governing Law</h2>
        <p>These Terms shall be governed by the laws of the State of Missouri, United States, without regard to its conflict of law provisions.</p>
        
        <h2>9. Contact Information</h2>
        <p>For any questions about these Terms, please contact us at toverahelp@gmail.com.</p>
        
        <h2>10. Privacy Policy</h2>
        <p>Please review our Privacy Policy, which also governs your use of the Service, to understand our practices.</p>
        
        <h2>11. Termination</h2>
        <p>We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      </div>
    </div>
  );
};