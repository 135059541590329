import React from "react";
import style from "./generative-search-input.module.css";

export const GenerativeSearchInput = ({
  searchInput,
  setSearchInput,
  handleGenerate,
  isLoading,
}) => (
  <div className={style["search-container"]}>
    <input
      type="text"
      value={searchInput}
      onChange={(e) => setSearchInput(e.target.value.slice(0, 100))}
      placeholder="Ask anything you want"
      maxLength={100}
      className={style["search-input"]}
    />
    <button
      onClick={handleGenerate}
      className={`${style["generate-button"]} ${isLoading ? style["generating"] : ""}`}
      disabled={isLoading || searchInput.trim() === ""}
    >
      Explore
    </button>
  </div>
);
