import { useCallback, useRef } from 'react';

export function useDebounce(func, delay, minConsecutiveChars = 3) {
  const timeoutRef = useRef(null);
  const consecutiveCharsRef = useRef(0);
  const lastInvocationRef = useRef(0);

  return useCallback((...args) => {
    const [text] = args;
    const lastChar = text.slice(-1);
    const now = Date.now();

    if (/\s|[.,!?]/.test(lastChar)) {
      consecutiveCharsRef.current = 0;
      if (now - lastInvocationRef.current >= delay) {
        func(...args);
        lastInvocationRef.current = now;
      }
    } else {
      consecutiveCharsRef.current += 1;
      if (consecutiveCharsRef.current >= minConsecutiveChars && now - lastInvocationRef.current >= delay) {
        func(...args);
        lastInvocationRef.current = now;
      }
    }
  }, [func, delay, minConsecutiveChars]);
}
