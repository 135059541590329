import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import style from "./login-modal.module.css";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import loginImage from "../../images/log-in-popup-image.jpg";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

export const LoginModal = ({
  auth,
  handleAuthStateChange,
  isLoginModalOpen,
  setIsLoginModalOpen,
}) => {
  const loginModalRef = useRef();
  const analytics = getAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        loginModalRef.current &&
        !loginModalRef.current.contains(event.target)
      ) {
        setIsLoginModalOpen(false);
      }
    };

    if (isLoginModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isLoginModalOpen, setIsLoginModalOpen]);

  const handleGoogleSignIn = () => {
    logEvent(analytics, `button_click_login`);
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((result) => {
        setIsLoginModalOpen(false);
        handleAuthStateChange(result.user);
        logEvent(analytics, `login_success`);
      })
      .catch((error) => {
        console.error("Error during Google sign-in:", error);
      });
  };

  const handleTermsClick = (e) => {
    e.preventDefault();
    setIsLoginModalOpen(false);
    navigate("/terms");
  };

  return (
    <div
      className={style["login-modal"]}
      id="loginModal"
      style={{ display: isLoginModalOpen ? "flex" : "none" }}
    >
      <div className={style["modal-content"]} ref={loginModalRef}>
        <button
          type="button"
          className={style["btn-close"]}
          aria-label="Close"
          onClick={() => setIsLoginModalOpen(false)}
        >
          &times;
        </button>
        <div className={style["form-container"]}>
          <h1 className={style["modal-title"]}>Welcome to Torsera</h1>
          <button
            className={style["google-sign-in"]}
            onClick={handleGoogleSignIn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="24px"
              height="24px"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              />
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              />
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              />
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              />
            </svg>
            Continue with Google
          </button>
          <div className={style["divider"]}>
            <span className={style["divider-text"]}>or</span>
          </div>
          <button className={style["placeholder-button"]} disabled>
            Continue with Mobile
          </button>
          <p className={style["terms-text"]}>
            By signing up, you agree to Torsera's{" "}
            <a
              href="/terms"
              className={style["terms-link"]}
              onClick={(e) => handleTermsClick(e)}
            >
              Terms of Use
            </a>
          </p>
        </div>
        <div className={style["image-container"]}>
          <img src={loginImage} alt="Login" className={style["login-image"]} />
        </div>
      </div>
    </div>
  );
};
