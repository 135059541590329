import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import style from "./landing-page.module.css";
import backgroundImage from "../../images/night-sky.png"; // Import the background image
import { AppContext } from "../../context/appContext";

export const LandingPage = () => {
  const [displayWord, setDisplayWord] = useState("");
  const words = useMemo(() => ["Generate", "Explore", "Discover"], []);
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const wordSwapRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false); // New state to track image loading
  const { setIsLoginModalOpen } = useContext(AppContext);

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = () => setIsLoaded(true);
  }, []);

  useEffect(() => {
    const typingInterval = 115;
    const deletingInterval = 85;
    const pauseInterval = 1600;

    const typeEffect = () => {
      const currentWord = words[wordIndex];
      const maxLength = Math.max(...words.map((word) => word.length));
      const offset = ((maxLength - currentWord.length) / 2) * 0.6; // Adjust 0.6 to fine-tune the offset

      if (wordSwapRef.current) {
        wordSwapRef.current.style.transform = `translateX(calc(-50% ))`;
      }

      if (isDeleting) {
        setDisplayWord(currentWord.substring(0, displayWord.length - 1));
      } else {
        setDisplayWord(currentWord.substring(0, displayWord.length + 1));
      }

      if (!isDeleting && displayWord === currentWord) {
        setIsTyping(false);
        setTimeout(() => {
          setIsTyping(true);
          setIsDeleting(true);
        }, pauseInterval);
      } else if (isDeleting && displayWord === "") {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    };

    const timer = setTimeout(
      typeEffect,
      isDeleting ? deletingInterval : typingInterval
    );
    return () => clearTimeout(timer);
  }, [displayWord, isDeleting, wordIndex, isTyping, words]);

  const handleCtaClick = () => {
    setIsLoginModalOpen(true);
  };

  if (!isLoaded) {
    return <div className={style["loading"]}></div>; // Show a loading state
  }

  return (
    <div className={`${style["landing-page"]} ${isLoaded ? style.loaded : ""}`}>
      <div className={style.hero}>
        <h1>
          <span className={style["word-swap-container"]}>
            <span
              className={`${style["word-swap"]} ${
                isTyping ? style.typing : ""
              }`}
              ref={wordSwapRef}
            >
              {displayWord}
            </span>
          </span>{" "}
          <span className={style["gradient-text"]}>Limitless</span>{" "}
          Narrative-Driven Videos in Seconds
        </h1>
        <p>
          We use{" "}
          <span className={style.highlight}>
            <strong>AI images</strong>
          </span>
          ,{" "}
          <span className={style.highlight}>
            <strong>voice</strong>
          </span>
          , and{" "}
          <span className={style.highlight}>
            <strong>music</strong>
          </span>{" "}
          <br></br>to bring your video ideas to life.
        </p>
        <button className={style["cta-button"]} onClick={handleCtaClick}>
          Create Now
        </button>
      </div>
    </div>
  );
};