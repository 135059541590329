import React from 'react';
import { FiUser, FiMail, FiLock, FiCalendar } from 'react-icons/fi';
import { motion } from 'framer-motion';

const AccountInfo = ({ userData }) => {
  return (
    <motion.div 
      className="bg-zinc-800 p-6 rounded-lg shadow-md h-full transition-all duration-300 hover:shadow-lg hover:bg-zinc-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-semibold text-zinc-100 mb-6 flex items-center">
        <FiUser className="w-6 h-6 mr-3 text-purple-400" />
        Account Info
      </h2>
      <div className="text-zinc-300 space-y-4">
        <InfoItem icon={FiMail} label="Email" value={userData?.email} />
        <InfoItem icon={FiLock} label="Login method" value={userData?.providerId || 'Email/Password'} />
      </div>
    </motion.div>
  );
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <motion.p 
    className="flex items-center"
    whileHover={{ x: 5 }}
    transition={{ duration: 0.2 }}
  >
    <Icon className="w-5 h-5 mr-3 text-purple-400" />
    <span className="font-medium mr-2">{label}:</span> {value}
  </motion.p>
);

export default AccountInfo;