import React, { useContext } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { AccountHome } from "./pages/AccountHome/AccountHome";
import { AppLayout } from "./AppLayout";
import { HomePage } from "./pages/AppDashboard/AppDashboard";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { PaymentSuccessPage } from "./pages/PaymentSuccessPage/PaymentSuccessPage";
import { TermsOfUse } from "./pages/Terms/TermsOfUse";

// Context
import { AppContext } from "./context/appContext";
import { GenerativeSearch } from "./pages/GenerativeSearch/GenerativeSearch";

// Create a ProtectedRoute component
const ProtectedRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/" replace />;
};

const createRouter = (isAuthenticated) =>
  createBrowserRouter([
    {
      path: "/*",
      element: <AppLayout />,
      children: [
        {
          path: "account",
          element: (
            <ProtectedRoute
              element={<AccountHome />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        {
          path: "payment-success",
          element: (
            <ProtectedRoute
              element={<PaymentSuccessPage />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        { path: "terms", element: <TermsOfUse /> },
        {
          path: "generative-search",
          element: (
            <ProtectedRoute
              element={<GenerativeSearch />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        {
          path: "*",
          element: isAuthenticated ? <HomePage /> : <LandingPage />,
        },
      ],
    },
  ]);

export const AppRoutes = ({ auth }) => {
  const { isAuthenticated } = useContext(AppContext);
  return <RouterProvider router={createRouter(isAuthenticated)} />;
};
