import React from "react";
import style from "./generative-search-suggestions.module.css";

const thumbnailUrl = "https://i.imgur.com/BkhIj2f.jpeg";

export const GenerativeSearchSuggestions = ({
  initialSuggestions,
  setSearchInput,
  fadeInIndices,
}) => {
  // Split suggestions into two rows
  const firstRow = initialSuggestions.slice(0, 3);
  const secondRow = initialSuggestions.slice(3);

  const renderSuggestion = (suggestion, index) => (
    <button
      key={index}
      onClick={() => setSearchInput(suggestion)}
      className={`${style["initial-suggestion"]} ${fadeInIndices.includes(index) ? style["fade-in-suggestion"] : ""}`}
    >
      <img
        src={thumbnailUrl}
        alt="thumbnail"
        style={{ width: "38px", height: "38px", marginRight: "8px" }}
      />
      {suggestion}
    </button>
  );

  return (
    <div className={style["suggestions-container"]}>
      <div className={style["suggestions-row"]}>
        {firstRow.map((suggestion, index) =>
          renderSuggestion(suggestion, index)
        )}
      </div>
      <div className={style["suggestions-row"]}>
        {secondRow.map((suggestion, index) =>
          renderSuggestion(suggestion, index + 3)
        )}
      </div>
    </div>
  );
};
