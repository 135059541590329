const RATE_LIMIT = 75;
const TIME_WINDOW = 30 * 60 * 1000; // 30min in milliseconds

const userGenerationHistory = new Map();

export function checkRateLimit(userId) {
  const now = Date.now();
  const userHistory = userGenerationHistory.get(userId) || [];

  // Remove entries older than the time window
  const recentHistory = userHistory.filter(timestamp => now - timestamp < TIME_WINDOW);

  if (recentHistory.length >= RATE_LIMIT) {
    const oldestTimestamp = recentHistory[0];
    const timeToWait = TIME_WINDOW - (now - oldestTimestamp);
    return {
      isLimited: true,
      timeToWait: Math.ceil(timeToWait / 1000) // Convert to seconds
    };
  }

  // Add current timestamp to history
  recentHistory.push(now);
  userGenerationHistory.set(userId, recentHistory);

  return {
    isLimited: false,
    timeToWait: 0
  };
}

export function resetRateLimit(userId) {
  userGenerationHistory.delete(userId);
}