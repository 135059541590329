import React from 'react';
import styles from './GenLoadingState.module.css';

const GenLoadingState = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className={styles.genLoadingState}>
      <div className={styles.gradientBorder}></div>
    </div>
  );
};

export default GenLoadingState;