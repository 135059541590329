import axios from "axios";
import {
  informationalWords,
  fictionalWords,
  unexpectedWords,
} from "../../constants/constants";

export const generateSearchIdeas = async (searchInput) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/generate_search_ideas`,
      { userInput: searchInput },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    const processedIdeas = await axios.post(
      `${process.env.REACT_APP_API_URL}/process_ideas`,
      { ideas: response.data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return processedIdeas.data.map((idea) => ({ ...idea, ready: true }));
  } catch (error) {
    console.error("Error generating ideas:", error);
    throw error;
  }
};

export function getRandomInitialSuggestions() {
  const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];
  return [
    getRandomItem(informationalWords),
    getRandomItem(fictionalWords),
    getRandomItem(unexpectedWords),
    getRandomItem([...informationalWords, ...fictionalWords]),
    getRandomItem([...fictionalWords, ...unexpectedWords]),
  ];
}

export async function handleGenerateClick(
  searchInput,
  isLoading,
  setIsLoading,
  setShowCards,
  setIdeas,
  ideas
) {
  if (searchInput.trim() !== "" && !isLoading) {
    setIsLoading(true);
    setShowCards(true);
    setIdeas(ideas.map(() => ({ ready: false })));

    try {
      const generatedIdeas = await generateSearchIdeas(searchInput);
      setIdeas(generatedIdeas);
    } catch (error) {
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  }
}
