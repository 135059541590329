import React, { useEffect, useState } from 'react';
import styles from './RateLimitToast.module.css';

const RateLimitToast = ({ message, duration, onClose }) => {
  const [visible, setVisible] = useState(true);
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setVisible(false);
          onClose();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className={styles.toast}>
      <p>{message}</p>
      <p>Time remaining: {timeLeft} seconds</p>
    </div>
  );
};

export default RateLimitToast;