import React, { useEffect, useState } from "react";
import style from "./generative-search.module.css";
import {
  getRandomInitialSuggestions,
  handleGenerateClick,
} from "./generative-search-util-function";

// Components
import { GenerativeSearchInput } from "./GenerativeSearchComponents/GenerativeSearchInput/GenerativeSearchInput";
import { GenerativeSearchSuggestions } from "./GenerativeSearchComponents/GenerativeSearchSuggestions/GenerativeSearchSuggestions";
import { GenerativeSearchIdeas } from "./GenerativeSearchComponents/GenerativeSearchIdeas/GenerativeSearchIdeas";

export const GenerativeSearch = () => {
  const [searchInput, setSearchInput] = useState("");
  const [ideas, setIdeas] = useState([
    { ready: false },
    { ready: false },
    { ready: false },
    { ready: false },
    { ready: false },
    { ready: false },
  ]);
  const [initialSuggestions, setInitialSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const [fadeInIndices, setFadeInIndices] = useState([]);

  useEffect(() => {
    const initialSuggestions = getRandomInitialSuggestions();
    setInitialSuggestions(initialSuggestions);
    initialSuggestions.forEach((_, index) => {
      setTimeout(() => {
        setFadeInIndices((prev) => [...prev, index]);
      }, 100 * index);
    });
  }, []);

  function handleGenerate() {
    handleGenerateClick(
      searchInput,
      isLoading,
      setIsLoading,
      setShowCards,
      setIdeas,
      ideas
    );
  }

  return (
    <div className={style["generative-search"]}>
      <div className={style["content-wrapper"]}>
        <GenerativeSearchInput
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleGenerate={handleGenerate}
          isLoading={isLoading}
        />
        {!showCards && (
          <GenerativeSearchSuggestions
            initialSuggestions={initialSuggestions}
            setSearchInput={setSearchInput}
            fadeInIndices={fadeInIndices}
          />
        )}
        {showCards && (
          <div
            className={`${style["cards-background-container"]} ${style["fade-in"]}`}
          >
            <GenerativeSearchIdeas
              ideas={ideas}
              loadedImages={loadedImages}
              setLoadedImages={setLoadedImages}
            />
          </div>
        )}
      </div>
    </div>
  );
};
