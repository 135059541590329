import React from 'react';
import styles from './MinimalLengthSelector.module.css';

const MinimalLengthSelector = ({ value, onChange }) => {
  return (
    <div className={styles.lengthSelectorContainer}>
      <span className={styles.lengthLabel}>Length:</span>
      <select 
        className={styles.minimalSelect}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="Auto">Auto</option>
        <option value="Short" disabled>Short</option>
        <option value="Medium" disabled>Medium</option>
        <option value="Extended" disabled>Extended</option>
        <option value="Extended+" disabled>Extended+</option>
      </select>
    </div>
  );
};

export default MinimalLengthSelector;