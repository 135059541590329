import React from "react";
import style from "./alert.module.css";

// Icons
import { Checkmark } from "../../icons/Checkmark";
import { Warning } from "../../icons/Warning";

export const Alert = ({ onClose, status, text }) => {
  function getStatus() {
    if (status === "success") {
      return style["success"];
    } else if (status === "warning") {
      return style["warning"];
    }
  }
  return (
    <div className={style["alert"] + " " + getStatus()}>
      {status === "success" ? <Checkmark /> : null}
      {status === "warning" ? <Warning /> : null}
      <span className={style.text}>{text}</span>
      <button className={style["close-btn"]} onClick={onClose}>
        &times;
      </button>
    </div>
  );
};
