import style from "./AppDashboard.module.css";
import axios from "axios";
import { checkRateLimit } from "../../utils/rateLimit";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

export function handleSubmit(
  storyPrompt,
  storyOptions,
  setAlertOptions,
  setIsLoading,
  setIsVideo,
  setVideoOptions,
  videoOptions,
  userId,
  setShowRateLimitToast,
  setRateLimitDuration,
  setIdeaInputText,
  setUserData
) {
  try {
    checkRateLimit(userId);
  } catch (error) {
    const duration = Math.ceil(error.message.match(/\d+/)[0] * 60);
    setShowRateLimitToast(true);
    setRateLimitDuration(duration);
    return;
  }

  setIsLoading(true);
  setIsVideo(true);
  logEvent(analytics, `button_click_generate_story`, {
    value: storyPrompt,
  });

  // document.addEventListener("contextmenu", function (event) {
  //   if (event.target.classList.contains(style["panning-image"])) {
  //     event.preventDefault();
  //   }
  // });

  // document
  //   .getElementById(style["download-button"])
  //   .addEventListener("click", function () {
  //     fetch("/generate_combined_video", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         user_id: currentUserId,
  //         story_id: currentStoryId,
  //       }),
  //     }).catch((error) => {
  //       console.error("Error:", error);
  //     });
  //   });

  // Prepare the request data with the selected story style and art style
  const requestData = {
    storyPrompt: storyPrompt,
    storyStyle: storyOptions.mode,
    addonReplaceMode: storyOptions.style,
    length: storyOptions.length,
    voice: storyOptions.voice,
    music: "Auto",
  };

  logEvent(analytics, `generate_story_options_style`, {
    value: storyOptions.mode,
  });
  logEvent(analytics, `generate_story_options_length`, {
    value: storyOptions.length,
  });
  logEvent(analytics, `generate_story_options_voice`, {
    value: storyOptions.voice,
  });

  // var isCursorOnImage;

  //   document.getElementById("loading").style.display = "block";

  // Hide the add/replace buttons
  //   document.getElementById("addon-art-style-button").style.display = "none";
  //   document.getElementById("replace-art-style-button").style.display = "none";

  // Hide the generate button
  //   document.querySelector(".submit-button-container").style.display = "none";

  axios
    .post(`${process.env.REACT_APP_API_URL}/process_story`, requestData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    // .then((response) => {
    //   if (!response.ok) {
    //     logEvent(analytics, `generate_story_error`);
    //     if (response.status === 401) {
    //       logEvent(analytics, `generate_story_error_unauthorized`);
    //       setIsLoading(false);
    //       setIsVideo(false);
    //       setAlertOptions({
    //         isOpen: true,
    //         text: "You are not authenticated. This may be a result of your cookie settings.",
    //         status: "warning",
    //       });
    //     } else {
    //       logEvent(analytics, `generate_story_error_other`);
    //       setIsLoading(false);
    //       setIsVideo(false);
    //       setAlertOptions({
    //         isOpen: true,
    //         text: "There was an error processing your request. Please try again.",
    //         status: "warning",
    //       });
    //     }
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }
    //   return response.json();
    // })
    .then((response) => {
      logEvent(analytics, `generate_story_success`);
      const data = response.data;
      setUserData((prev) => ({ ...prev, credits: data.remainingCredits }));
      const _finalPromptResponseHistory = data.finalPromptResponseHistory;
      const currentStoryId = data.story_id;
      setIdeaInputText("");
      // Fade out the loader when images are ready

      // After the transition, hide the loader and display other elements
      setTimeout(() => {
        setIsLoading(false);

        const imageContainer = document.getElementById(
          style["image-container"]
        );

        // Sort the image paths based on their prompt index
        const sortedImagePaths = data.imagePaths.sort((a, b) => {
          const aIndex = extractPromptIndex(a);
          const bIndex = extractPromptIndex(b);
          return aIndex - bIndex;
        });

        setVideoOptions({
          ...videoOptions,
          imgPaths: [...sortedImagePaths],
          musicFileUrl: data.musicFileUrl,
        });

        // Helper function to extract the prompt index from the image URL
        function extractPromptIndex(url) {
          const match = url.match(/ai_art_prompt_(\d+)_\d+\.png/);
          return match ? parseInt(match[1], 10) : 0;
        }

        // Preload the first image
        if (sortedImagePaths.length > 0) {
          preloadImage(sortedImagePaths[0]);
        }

        // Show the subtitle toggle, label, play button, and image container
        // document.getElementById(style["back-button"]).style.display =
        //   "inline";
        // document.getElementById(style["subtitle-toggle-label"]).style.display =
        //   "inline";
        // document.getElementById(style["play-button"]).style.display = "block";
        imageContainer.style.display = "block";

        // Load the voiceover audio file
        const audioPlayer = document.getElementById(style["voiceover-audio"]);
        audioPlayer.src = data.voiceoverUrl;
        audioPlayer.load();

        // Load the background music file
        const backgroundMusic = document.getElementById(
          style["background-music"]
        );
        backgroundMusic.src = data.musicFilePath;
        backgroundMusic.load();

        // // Set the background music volume to 30% after it's loaded
        backgroundMusic.addEventListener("loadedmetadata", function () {
          backgroundMusic.volume = 0.3;
        });

        // Store the adjusted segment durations
        setVideoOptions((prev) => ({
          ...prev,
          segmentDurations: data?.adjustedSegmentDurations,
        }));

        // Apply animation durations to images
        applyAnimationDurations(
          document.querySelectorAll(".panning-image"),
          data?.adjustedSegmentDurations
        );

        // Fetch and process SRT file
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/get_srt_content`,
            { srt_url: data.srtUrl },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            const subtitles = parseSRT(response.data.srt_content);
            setVideoOptions((prev) => ({
              ...prev,
              subtitles: subtitles,
            }));
          })
          .catch((error) => {
            console.error("Error fetching or processing SRT file:", error);
          });

        // Reset slideshow state
      }, 850); // 850ms matches the duration of the fade-out transition
    })
    .catch((error) => {
      console.error("Error:", error);
      setIsLoading(false);
      setIsVideo(false);

      if (error.response) {
        logEvent(analytics, `generate_story_error`);
        if (error.response.status === 401) {
          logEvent(analytics, `generate_story_error_unauthorized`);
          setAlertOptions({
            isOpen: true,
            text: "You are not authenticated. This may be a result of your cookie settings.",
            status: "warning",
          });
        } else {
          logEvent(analytics, `generate_story_error_other`);
          setAlertOptions({
            isOpen: true,
            text: "There was an error processing your request. Please try again.",
            status: "warning",
          });
        }
      } else {
        setAlertOptions({
          isOpen: true,
          text: "There was an error processing your request. Please try again.",
          status: "warning",
        });
      }
    });
}

function applyAnimationDurations(images, segmentDurations) {
  images.forEach((img, index) => {
    if (index < segmentDurations.length) {
      img.style.animationDuration = `${segmentDurations[index]}s`;
    }
  });
}

function preloadImage(url) {
  var img = new Image();
  img.src = url;
}

function parseSRT(srt) {
  const subtitles = [];
  const srtLines = srt.split("\n\n");
  for (const line of srtLines) {
    const parts = line.split("\n");
    if (parts.length >= 3) {
      const times = parts[1].split(" --> ");
      subtitles.push({
        start: timeToSeconds(times[0]),
        end: timeToSeconds(times[1]),
        text: parts.slice(2).join(" "),
      });
    }
  }
  return subtitles;
}

function timeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(":");
  const [secs, millis] = seconds.split(",");
  return (
    Number(hours) * 3600 +
    Number(minutes) * 60 +
    Number(secs) +
    Number(millis) / 1000
  );
}
