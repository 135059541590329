import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { CrystalBall } from "../../icons/CrystalBall";
import styles from "./Navbar.module.css";
import BorderGlowButton from "./PricingButton.tsx";
import '@flaticon/flaticon-uicons/css/all/all.css';

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

export const Navbar = () => {
  const {
    isAuthenticated,
    setIsLoginModalOpen,
    setIsPricingModalOpen,
    setIsVideo,
    setVideoOptions,
    userData,
  } = useContext(AppContext);

  const analytics = getAnalytics();

  const navigate = useNavigate();

  const handleHomeClick = () => {
    setIsVideo(false);
    setVideoOptions(() => ({
      currentIndex: null,
      isPlaying: false,
      intervalId: null,
      startTime: null,
      elapsedTime: null,
      segmentDurations: [],
      imgPaths: [],
      subtitles: [],
      subtitleIntervalId: null,
    }));
    navigate("/");
    logEvent(analytics, "button_click_home");
  };

  return (
    <header>
      <div className={`${styles.topBar} ${isAuthenticated ? "" : styles.dark}`}>
        <button className={styles.siteName} onClick={handleHomeClick}>
          <div className={styles.logoContainer}>
            <CrystalBall className={styles.crystalBall} />
            <img
              src="/images/Torsera-Invert.avif"
              alt="Tovera Logo"
              className={styles.logoImage}
            />
          </div>
        </button>
        {isAuthenticated ? (
          <nav className={styles.topBarNav}>
            <div className={styles.creditsDisplay}>
              <i className="fi fi-br-coins"></i>
              <span className={styles.creditsNumber}>{userData?.credits || 0}</span>
            </div>
            <div className={styles.pricingButtonWrapper}>
              <BorderGlowButton onClick={() => {
                setIsPricingModalOpen(true);
                logEvent(analytics, `button_click_open_pricing_modal_navbar`);
              }} />
            </div>
            <button
              className={styles.headerButton}
              id="userInitial"
              onClick={() => {
                navigate("/account");
                logEvent(analytics, `button_click_account`);
              }}
            >
              {userData.initial}
            </button>
          </nav>
        ) : (
          <nav className={styles.topBarNav}>
            <div
              className={styles.loginButton}
              onClick={() => setIsLoginModalOpen(true)}
            >
              Login
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};