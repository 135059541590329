export const informationalWords = [
  "science",
  "history",
  "technology",
  "psychology",
  "economics",
  "philosophy",
  "biology",
  "astronomy",
  "geography",
  "anthropology",
];
export const fictionalWords = [
  "fantasy",
  "sci-fi",
  "mystery",
  "adventure",
  "dystopia",
  "superhero",
  "fairy tale",
  "time travel",
  "alternate history",
  "cyberpunk",
];
export const unexpectedWords = [
  "fun things to do on the moon",
  "disco plants",
  "ninja accountants",
  "space origami",
  "underwater disco",
  "time-traveling sloths",
  "breakdancing molecules",
  "telepathic sushi",
  "cosmic knitting",
  "interdimensional karaoke",
];
export const exampleInputs = [
  { input: "china", suggestions: ["culture", "communism", "idea espionage"] },
  {
    input: "space",
    suggestions: ["danger", "colonization", "alien civilizations"],
  },
  {
    input: "artificial intelligence",
    suggestions: ["ethics", "job displacement", "superintelligence"],
  },
  {
    input: "climate change",
    suggestions: ["renewable energy", "geoengineering", "catastrophe"],
  },
];
