import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./AppDashboard.module.css";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// Components
import { DropDownSelector } from "../../components/DropDownSelector/DropDownSelector";

// Context
import { AppContext } from "../../context/appContext";

// Icons
import { ChevronLeft } from "../../icons/ChevronLeft";

export const AppVideoContent = () => {
  const { isLoading, setIsVideo, setVideoOptions, videoOptions } =
    useContext(AppContext);

  const analytics = getAnalytics();

  const [isFirstPlay, setIsFirstPlay] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedTime, setPausedTime] = useState(null);
  const [totalPausedTime, setTotalPausedTime] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const imageContainerRef = useRef(null);
  const animationRef = useRef(null);
  const subtitleRef = useRef(null);
  const voiceoverRef = useRef(null);
  const backgroundMusicRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);

    return () => clearTimeout();
  }, []);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (subtitleRef.current) {
        cancelAnimationFrame(subtitleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoOptions.isPlaying) {
      animationRef.current = requestAnimationFrame(animateImage);
      subtitleRef.current = requestAnimationFrame(updateSubtitle);
    }
  }, [videoOptions.isPlaying, videoOptions.currentIndex]);

  useEffect(() => {
    const voiceoverAudio = document.getElementById(style["voiceover-audio"]);
    const backgroundMusic = document.getElementById(style["background-music"]);

    if (voiceoverAudio && backgroundMusic) {
      voiceoverAudio.addEventListener("play", () => {
        backgroundMusic.play();
      });

      voiceoverAudio.addEventListener("pause", () => {
        backgroundMusic.pause();
      });

      voiceoverAudio.addEventListener("ended", () => {
        backgroundMusic.pause();
      });

      backgroundMusic.loop = true;
      backgroundMusic.volume = 0.3;
    }

    return () => {
      if (voiceoverAudio && backgroundMusic) {
        voiceoverAudio.removeEventListener("play", () => {});
        voiceoverAudio.removeEventListener("pause", () => {});
        voiceoverAudio.removeEventListener("ended", () => {});
      }
    };
  }, []);

  useEffect(() => {
    const dimmingOverlay = document.createElement("div");
    dimmingOverlay.className = style["dimming-overlay"];
    document.body.appendChild(dimmingOverlay);

    return () => {
      document.body.removeChild(dimmingOverlay);
    };
  }, []);

  function handleGoBack() {
    logEvent(analytics, `button_click_back_to_prompt`);
    const subtitleContainer = document.getElementById(
      style["subtitle-container"]
    );
    if (subtitleContainer) {
      subtitleContainer.innerText = "";
      subtitleContainer.style.display = "none";
    }
    cancelAnimationFrame(animationRef.current);
    cancelAnimationFrame(subtitleRef.current);
    setIsVideo(false);
    pauseSlideshow();
    setVideoOptions(() => ({
      currentIndex: null,
      isPlaying: false,
      intervalId: null,
      startTime: null,
      elapsedTime: null,
      segmentDurations: [],
      imgPaths: [],
      subtitles: [],
      subtitleIntervalId: null,
    }));

    const dimmingOverlay = document.querySelector(
      `.${style["dimming-overlay"]}`
    );
    dimmingOverlay.style.opacity = "0";
    setTimeout(() => {
      dimmingOverlay.style.display = "none";
      document
        .querySelectorAll(".pricing-button, .header-button")
        .forEach((button) => {
          button.classList.remove("dimmed");
        });
    }, 300);
  }

  function handlePlayClick() {
    logEvent(analytics, `button_click_play`);
    if (!videoOptions.isPlaying) {
      const imageContainer = imageContainerRef.current;

      if (isFirstPlay) {
        logEvent(analytics, `video_start_first`);
        imageContainer.classList.add(style["fade-in"]);
        imageContainer.addEventListener(
          "animationend",
          () => {
            imageContainer.classList.remove(style["fade-in"]);
          },
          { once: true }
        );
        setIsFirstPlay(false);
      }

      if (isPaused) {
        const currentImage = imageContainerRef.current.querySelector(
          `.${style["panning-image"]}.${style["active"]}`
        );
        currentImage.classList.remove(style.paused);
        setIsPaused(false);
      }
      startSlideshow();

      const voiceoverAudio = document.getElementById(style["voiceover-audio"]);
      const backgroundMusic = document.getElementById(
        style["background-music"]
      );

      if (voiceoverAudio && backgroundMusic) {
        voiceoverAudio.play();
      }

      const dimmingOverlay = document.querySelector(
        `.${style["dimming-overlay"]}`
      );
      dimmingOverlay.classList.add("play");
      dimmingOverlay.classList.remove("pause");
      dimmingOverlay.style.display = "block";
      setTimeout(() => {
        dimmingOverlay.style.opacity = "1";
        document
          .querySelectorAll(".pricing-button, .header-button")
          .forEach((button) => {
            button.classList.add("dimmed");
          });
      }, 0);
    }
  }

  function getNextIndex(currentIndex, totalImages) {
    if (currentIndex + 1 >= totalImages) {
      logEvent(analytics, `video_end`);
      cancelAnimationFrame(animationRef.current);
      cancelAnimationFrame(subtitleRef.current);
      const audioPlayer = document.getElementById(style["voiceover-audio"]);
      pauseSlideshow();
      audioPlayer.currentTime = 0;
      setVideoOptions((prev) => ({
        ...prev,
        currentIndex: 0,
        startTime: null,
        elapsedTime: 0,
        isPlaying: false,
        intervalId: 0,
        elapsedTime: 0,
        subtitleIntervalId: null,
      }));
      const images = document.getElementsByClassName(style["panning-image"]);
      for (var i = 0; i < images.length; i++) {
        images[i].style.transform = `translateX(0%)`;
      }
      const subtitleContainer = document.getElementById(
        style["subtitle-container"]
      );
      if (subtitleContainer) {
        subtitleContainer.innerText = "";
        subtitleContainer.style.display = "none";
      }
    } else {
      return (currentIndex + 1) % totalImages;
    }
  }

  // const[localTime, setLoacalTime] = useState(0)
  const startTimeRef = useRef(null);

  function animateImage(timestamp) {
    if (videoOptions.isPlaying) {
      let startTime = startTimeRef.current;
      if (!startTime) {
        startTime = timestamp;
        startTimeRef.current = startTime;
        setVideoOptions((prev) => {
          return { ...prev, startTime: startTime };
        });
      }

      const elapsed = timestamp - startTime + videoOptions.elapsedTime;

      const duration =
        videoOptions.segmentDurations[videoOptions.currentIndex || 0] * 1000;

      const progress = Math.min(elapsed / duration, 1);

      const currentImage = imageContainerRef.current.querySelector(
        `.${style["panning-image"]}.${style["active"]}`
      );
      if (currentImage) {
        const translateX = -19.5 * progress;
        // const panTime = duration / 1000;
        currentImage.style.transform = `translateX(${translateX}%)`;
        // currentImage.style.animation = `pan ${panTime}s linear infinite`;
      }

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateImage);
      } else {
        startTimeRef.current = null;
        goToNextImage();
      }
    }
  }

  function goToNextImage() {
    cancelAnimationFrame(subtitleRef.current);
    setVideoOptions((prev) => {
      const nextIndex = getNextIndex(prev.currentIndex, prev.imgPaths.length);
      return {
        ...prev,
        // isPlaying: true,
        elapsedTime: 0,
        currentIndex: nextIndex,
        startTime: null,
      };
    });

    // if (videoOptions.isPlaying) {
    //   animationRef.current = requestAnimationFrame(animateImage);
    // }
  }

  function startSlideshow() {
    const images = imageContainerRef.current.querySelectorAll(
      `.${style["panning-image"]}`
    );
    if (images.length > 0) {
      const audioPlayer = document.getElementById(style["voiceover-audio"]);

      imageContainerRef.current.style.border = "3px solid #000";
      document.getElementById(style["subtitle-container"]).style.display =
        "block";
      document.getElementById(style["subtitle-container"]).style.opacity = "1";
      setVideoOptions((prev) => ({
        ...prev,
        currentIndex: prev.currentIndex || 0,
        isPlaying: true,
        startTime: pausedTime ? performance.now() - pausedTime : null,
      }));

      startTimeRef.current = null;

      animationRef.current = requestAnimationFrame(animateImage);
      subtitleRef.current = requestAnimationFrame(updateSubtitle);

      audioPlayer.addEventListener("timeupdate", updateProgressBar);
    }
  }

  function pauseSlideshow(timestamp) {
    logEvent(analytics, `button_click_pause`);
    if (animationRef.current) {
      setIsPaused(true);
      cancelAnimationFrame(animationRef.current);
      cancelAnimationFrame(subtitleRef.current);
    }

    if (subtitleRef.current) {
      cancelAnimationFrame(subtitleRef.current);
    }

    const currentImage = imageContainerRef.current.querySelector(
      `.${style["panning-image"]}.${style["active"]}`
    );
    if (currentImage) {
      currentImage.classList.add(style.paused);
    }

    setPausedTime(timestamp);

    setVideoOptions((prev) => ({
      ...prev,
      startTime: timestamp,
      elapsedTime: timestamp - startTimeRef.current + prev.elapsedTime,
      isPlaying: false,
    }));

    const audioPlayer = document.getElementById(style["voiceover-audio"]);
    audioPlayer.pause();
    audioPlayer.removeEventListener("timeupdate", updateProgressBar);

    const dimmingOverlay = document.querySelector(
      `.${style["dimming-overlay"]}`
    );
    dimmingOverlay.classList.add("pause");
    dimmingOverlay.classList.remove("play");
    dimmingOverlay.style.opacity = "0";
    setTimeout(() => {
      dimmingOverlay.style.display = "none";
      document
        .querySelectorAll(".pricing-button, .header-button")
        .forEach((button) => {
          button.classList.remove("dimmed");
        });
    }, 350);
  }

  function updateSubtitle() {
    if (videoOptions.isPlaying) {
      const currentTime = document.getElementById(
        style["voiceover-audio"]
      )?.currentTime;
      const subtitleIndex = videoOptions.subtitles.findIndex(
        (s) => currentTime >= s.start && currentTime < s.end
      );
      const subtitleContainer = document.getElementById(
        style["subtitle-container"]
      );

      if (subtitleContainer) {
        if (subtitleIndex !== -1) {
          const subtitle = videoOptions.subtitles[subtitleIndex];
          subtitleContainer.innerText = subtitle.text;
          subtitleContainer.style.display = "block";
          subtitleContainer.style.opacity = "1";
        } else {
          // Instead of clearing the text, we'll keep the last subtitle
          if (subtitleContainer.innerText === "") {
            subtitleContainer.style.display = "none";
          } else {
            subtitleContainer.style.display = "block";
            subtitleContainer.style.opacity = "1";
          }
        }
      }

      subtitleRef.current = requestAnimationFrame(updateSubtitle);
    }
  }

  function updateProgressBar() {
    const audio = document.getElementById(style["voiceover-audio"]);
    const progress = (audio?.currentTime / audio?.duration) * 100;
    const progressBar = document.getElementById(style["progress-bar"]);
    if (progressBar) {
      progressBar.style.width = progress + "%";
    }
  }

  return (
    <div className={style["video-content"]}>
      {isLoading && (
        <div className={`${style.loader} ${loaded ? style.loading : ""}`}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}

      <>
        <div
          id={style["image-container"]}
          ref={imageContainerRef}
          style={{ display: isLoading ? "none" : "block" }}
        >
          <div className={style["glossy-overlay"]}></div>
          <div id={style["progress-bar"]}></div>
          <div
            id={style["controls-container-inner"]}
            style={{ display: isFirstPlay ? "block" : "" }}
          >
            {videoOptions.isPlaying && !isLoading ? (
              <button
                id={style["pause-button"]}
                className={style["icon-button"]}
                onClick={() => requestAnimationFrame(pauseSlideshow)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                </svg>
              </button>
            ) : (
              <button
                id={style["play-button"]}
                className={style["icon-button"]}
                onClick={() => handlePlayClick()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z" />
                </svg>
              </button>
            )}
          </div>
          {videoOptions.imgPaths.map((path, index) => (
            <img
              key={index}
              src={path}
              alt={`Video frame ${index + 1}`}
              className={`${style["panning-image"]} ${
                videoOptions.currentIndex === index ? style["active"] : ""
              }`}
              style={{ transform: "translateX(0%)" }}
            />
          ))}
        </div>
      </>

      <div id={style["subtitle-container"]} style={{ display: "none" }}></div>

      <div
        id={style["controls-container"]}
        style={{ display: isLoading ? "none" : "block" }}
      >
        <button className={style["back-button"]} onClick={() => handleGoBack()}>
          <ChevronLeft height="18px" width="18px" color="rgb(126, 79, 184)" />
          Back to Prompt
        </button>
        {/* <label
          htmlFor="subtitle-toggle"
          id={style["subtitle-toggle-label"]}
          style={{
            color: "white",
            fontFamily: "'Trebuchet MS', sans-serif",
            display: "none",
          }}
        >
          Subtitles:
        </label>
        <input
          type="checkbox"
          id={style["subtitle-toggle"]}
          checked
          style={{ display: "none" }}
        /> */}
      </div>
      <audio
        id={style["voiceover-audio"]}
        ref={voiceoverRef}
        src={videoOptions.voiceoverUrl}
        preload="auto"
      ></audio>
      <audio
        id={style["background-music"]}
        ref={backgroundMusicRef}
        src={videoOptions.musicFileUrl}
        preload="auto"
        loop
      ></audio>

      <div
        id={style["new-continue-buttons"]}
        style={{ display: "none", marginTop: "20px" }}
      >
        <button id={style["continue-button"]}>Continue</button>
        <button id={style["new-button"]}>New</button>
        <button id={style["download-button"]}>Export</button>
      </div>

      <div
        id={style["continue-input-container"]}
        style={{ display: "none", marginTop: "20px" }}
      >
        <input
          type="text"
          id={style["continue-input"]}
          placeholder="Enter your continuation"
        />
        <button id={style["submit-continue"]}>Submit</button>

        <div>
          <button
            type="button"
            id={style["addon-art-style-button"]}
            style={{ display: "none" }}
          >
            Add Art Style
          </button>
          <button
            type="button"
            id={style["replace-art-style-button"]}
            style={{ display: "none" }}
          >
            Replace Art Style
          </button>
        </div>
      </div>
    </div>
  );
};
