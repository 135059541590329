import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { AppContext } from "../../context/appContext";
import AccountInfo from "./AccountInfo";
import SubscriptionManagement from "./SubscriptionManagement";
import { motion } from "framer-motion";
import { FiUser, FiCreditCard, FiLogOut } from "react-icons/fi";
import styles from "./account-home.module.css";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const AccountHome = () => {
  const { auth, setIsPricingModalOpen, setUserData, userData } =
    useContext(AppContext);
  const navigate = useNavigate();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/", { replace: true });
    }
  }, [auth, navigate, userData]);

  const handleLogout = async () => {
    logEvent(analytics, `button_click_logout`);
    try {
      await signOut(auth);
      setUserData(null);
      navigate("/", { replace: true });
    } catch (err) {
      console.error("Logout error:", err.message);
    }
  };

  return (
    <div
      className={`min-h-screen bg-[#141414] text-zinc-100 py-12 px-4 sm:px-6 lg:px-8 ${styles.accountPage}`}
    >
      <div className="max-w-4xl mx-auto">
        <motion.nav {...fadeInUp} className="text-sm mb-8">
          <button
            onClick={() => navigate("/")}
            className="text-purple-400 hover:text-purple-300 font-medium"
          >
            Home
          </button>{" "}
          / <span className="font-light">Account Management</span>
        </motion.nav>

        <motion.h1
          {...fadeInUp}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="text-4xl font-bold text-center mb-12 tracking-tight"
        >
          Account Management
        </motion.h1>

        <motion.div
          {...fadeInUp}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-zinc-800 rounded-lg p-8 mb-8 flex items-center justify-center"
        >
          <div
            className={`bg-zinc-700 rounded-full p-4 mr-6 ${styles.userIcon}`}
          >
            <FiUser className="w-16 h-16 text-purple-400" />
          </div>
          <div>
            <p className="text-2xl font-semibold tracking-wide">
              {userData?.email}
            </p>
            <p className="text-lg text-purple-400 flex items-center mt-2 font-medium">
              <FiCreditCard className="w-5 h-5 mr-2" />
              Credits: {userData?.credits}
            </p>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <motion.div {...fadeInUp} transition={{ duration: 0.5, delay: 0.3 }}>
            <AccountInfo userData={userData} />
          </motion.div>
          <motion.div {...fadeInUp} transition={{ duration: 0.5, delay: 0.4 }}>
            <SubscriptionManagement
              userData={userData}
              onOpenPricingModal={() => {
                setIsPricingModalOpen(true);
                logEvent(
                  analytics,
                  `button_click_open_pricing_modal_account_home`
                );
              }}
            />
          </motion.div>
        </div>

        <motion.div
          {...fadeInUp}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="text-center"
        >
          <button
            onClick={handleLogout}
            className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors duration-300 flex items-center mx-auto font-medium"
          >
            <FiLogOut className="w-5 h-5 mr-2" />
            Logout
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default AccountHome;
