import React from "react";
import { useNavigate } from "react-router-dom";
import style from "./payment-success-page.module.css"; // Assuming you have some CSS for styling

export const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  const handleGoToAccount = () => {
    navigate("/account");
  };

  return (
    <div className={style.container}>
      <div className={style.card}>
        <h1 className={style.title}>Payment Successful!</h1>
        <p className={style.message}>
          Thank you for your subscription. Your payment was successful.
        </p>
        <button className={style.button} onClick={handleGoToAccount}>
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};
