import React, { useState, useEffect } from 'react';
import RateLimitToast from '../RateLimitToast/RateLimitToast';

const PreviewRateLimit = ({ children }) => {
  const [imageCount, setImageCount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const limit = 20;
  const timeWindow = 30000; // 30 seconds in milliseconds

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageCount(0);
      setIsRateLimited(false);
    }, timeWindow);

    return () => clearTimeout(timer);
  }, [imageCount]);

  const incrementCount = () => {
    setImageCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount > limit) {
        setShowToast(true);
        setIsRateLimited(true);
        return limit;
      }
      return newCount;
    });
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  return (
    <>
      {React.cloneElement(children, { incrementCount, isRateLimited })}
      {showToast && (
        <RateLimitToast
          message="Rate limit exceeded. Please wait before generating more images."
          duration={60}
          onClose={handleCloseToast}
        />
      )}
    </>
  );
};

export default PreviewRateLimit;