import React, { useContext } from "react";
import "./styles/App.css";

import { Outlet } from "react-router-dom";

// Components
import { Alert } from "./components/Alert/Alert";
import { Navbar } from "./components/Navbar/Navbar";
import { PricingModal } from "./components/PricingModal/PricingModal";
import { LoginModal } from "./components/LoginModal/LoginModal";

// Context
import { AppContext } from "./context/appContext";

export const AppLayout = () => {
  const {
    alertOptions,
    auth,
    handleAuthStateChange,
    isLoginModalOpen,
    isPricingModalOpen,
    pricingOptions,
    setAlertOptions,
    setIsLoginModalOpen,
    setIsPricingModalOpen,
  } = useContext(AppContext);

  function handleClose() {
    setAlertOptions({ ...alertOptions, isOpen: false });
  }
  return (
    <div className="App">
      <PricingModal
        isPricingModalOpen={isPricingModalOpen}
        pricingOptions={pricingOptions}
        setIsPricingModalOpen={setIsPricingModalOpen}
      />
      <LoginModal
        auth={auth}
        handleAuthStateChange={handleAuthStateChange}
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
      />
      {alertOptions.isOpen && (
        <Alert
          status={alertOptions.status}
          text={alertOptions.text}
          onClose={handleClose}
        />
      )}
      <Navbar />
      <Outlet />
    </div>
  );
};
