import React, { useEffect, useRef, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import styles from "./ToggleDictation.module.css";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

const ToggleDictation = ({
  onTextChange,
  interimText,
  setInterimText,
  setIsListening,
  isListening,
}) => {
  const analytics = getAnalytics();
  const recognitionRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;

      recognitionRef.current.onresult = (event) => {
        let interimTranscript = "";
        let finalTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        setInterimText(interimTranscript);
        if (finalTranscript !== "") {
          onTextChange(finalTranscript);
          setInterimText("");
        }
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
        setInterimText("");
      };

      recognitionRef.current.onerror = (event) => {
        console.error("Speech recognition error", event.error);
        setIsListening(false);
        setInterimText("");
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const startListening = () => {
    recognitionRef.current?.start();
    setIsListening(true);
    resetInactivityTimer();
  };

  const stopListening = () => {
    recognitionRef.current?.stop();
    setIsListening(false);
    setInterimText("");
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const toggleListening = () => {
    if (isListening) {
      logEvent(analytics, `button_click_stop_dictation`);
      stopListening();
    } else {
      logEvent(analytics, `button_click_start_dictation`);
      startListening();
    }
  };

  const resetInactivityTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      if (isListening) {
        stopListening();
      }
    }, 3500); // Stop after 3.5 seconds of inactivity
  };

  return (
    <button
      onClick={toggleListening}
      className={`${styles.dictationButton} ${isListening ? styles.listening : ''}`}
    >
      <FaMicrophone className={styles.icon} />
    </button>
  );
};

export default ToggleDictation;
