import React from "react";
import style from "./generative-search-ideas.module.css";

export const GenerativeSearchIdeas = ({
  ideas,
  loadedImages,
  setLoadedImages,
}) => (
  <div className={style["ideas-container"]}>
    {ideas.slice(0, 6).map((idea, index) => (
      <div key={index} className={style["card-wrapper"]}>
        {idea.ready ? (
          <img
            src={idea.imageUrl}
            alt={`Piece ${index}`}
            className={`${style["idea-image"]} ${loadedImages[index] ? style["fade-in"] : ""}`}
            onLoad={() => {
              setLoadedImages((prev) => ({ ...prev, [index]: true }));
            }}
          />
        ) : (
          <div className={style["loading-card"]}>
            <div className={style["loading-animation"]}>
              {/* Your loading animation JSX here */}
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
);
