import React from "react";
import style from "./loading-dots.module.css";

export const LoadingDots = () => {
  return (
    <div className={style.dots}>
      <div className={style.dot}></div>
      <div className={style.dot}></div>
      <div className={style.dot}></div>
    </div>
  );
};
